import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [];

export const dictionary = {
		"/(welcome)": [14],
		"/admin/activity-log": [15,[3]],
		"/admin/flagged-conversations": [16,[3]],
		"/admin/rule-management": [17,[3]],
		"/admin/settings/ai-settings": [18,[3,4]],
		"/admin/settings/company-settings": [19,[3,4]],
		"/admin/settings/rule-settings": [20,[3,4]],
		"/admin/settings/tags-settings": [21,[3,4]],
		"/admin/usage-insights/costs": [22,[3,5]],
		"/admin/usage-insights/users": [23,[3,5]],
		"/admin/user-management": [24,[3]],
		"/app/chat": [25,[6,7]],
		"/app/settings/user-profile": [26,[6]],
		"/ari": [27],
		"/(login)/confirm-password-reset/[token]": [9,[2]],
		"/(login)/forgot-password": [10,[2]],
		"/josh": [28],
		"/(login)/login": [11,[2]],
		"/(login)/logout": [12,[2]],
		"/(login)/register": [13,[2]],
		"/share/chat/[uuid]": [29,[8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';